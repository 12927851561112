.ag-theme-alpine {

  /* --ag-grid-size: 8px; */
  /* --ag-row-height: calc(var(--ag-grid-size) * 6); */
  /* --ag-list-item-height: calc(var(--ag-grid-size) * 4); */
  /* --ag-font-size: 13px; */
  /* --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 3); */

  /* --ag-grid-size: 8px; */
  /* --ag-font-size: 10px; */
  /* --ag-row-height: calc(var(--ag-grid-size) * 5); */
  /* --ag-list-item-height: calc(var(--ag-grid-size) * 4); */
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 0.5);
  --ag-value-change-value-highlight-background-color: yellow;
  /* --ag-border-color: grey; */
  --ag-odd-row-background-color: rgb(235, 235, 235);


  --ag-borders: none;
  --ag-border-color: lightgrey;
  --ag-borders-critical: solid 1px;

}


.align-center .ag-header-cell-label {
  justify-content: center;
}

.ag-cell-wrapper {
  max-width: 100%;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.centered-cell {
  justify-content: center;
}
